import React, { useEffect, useRef, useState } from 'react'
import { 
    WiserLogo, 
    InfoAreaForm, 
    InfoAreaHeadingContainer, 
    InfoAreaSubHeading, 
    OptionsContainer
} from "./styles";
import ProductLogo from "../../assets/image/gaa-logo.png";
import { 
    Error, 
    InputField, 
    RoundedButton, 
    RoundedInputField, 
    ShowOrHideOption, 
} from "components";
import Layout from '../../layout/authLayout'
import { useNavigate, useParams } from 'react-router';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useDispatch } from 'react-redux';
import { validateField } from 'services/Utils/Validator';
import { 
    MGaaLogo, 
    MInfoAreaForm, 
    MInfoAreaHeading, 
    MInfoAreaSubHeading, 
    MInputFieldsContainer 
} from './styles/style.Auth.Mobile';
import { TrademarkLogo } from './styles/style.Auth.Screen';
import { setPassword } from 'store/Requests/auth';
import { ROUTE_LOGIN } from 'services/Constants/constant';
import { handleGAAIconClick } from './utils/utils';

function SetPassword(props) {
    
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const isDeviceMobile = width < height;

    const params = useParams();
    const nameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const confirmPasswordInputRef = useRef(null);
    const SubmitButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const FORM_FIELD_TYPE_text = "text";
    const FORM_FIELD_TYPE_password = "password";
    const FORM_FIELD_TYPE_confirm_password = "confirmPassword";
    const FORM_FIELD_PLACEHOLDER_name = "Name";
    const FORM_FIELD_PLACEHOLDER_new_password = "New password";
    const FORM_FIELD_PLACEHOLDER_confirm_password = "Confirm password";
    const TEXT_create_account = 'Create account';
    const TEXT_alert_message = "Password has been set. Please login into your account.";
    const TEXT_FORM_subheading = "Please create your personal account and choose a new password.";

    const [ data, setData ] = useState({
        name: "",
        password: "",
        confirmPassword: ""
    });
    
    const [ loading, setLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ focusedFields, setFocusedFields ] = useState({
        name: false,
        password: false,
        confirmPassword: false
    });
    const [ fieldsFocusedAtLeastOnce, setFieldsFocusedAtLeastOnce ] = useState({
        name: false,
        password: false,
        confirmPassword: false
    });

    const [ errorMessages, setErrorMessages ] = useState({ 
        "areValid": true,
        "errors": {
            "name": { 
                isValid: true, 
                error: null
            },
            "confirmPassword": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleInputChange = (input, newValue) => {
        setData({
            ...data, 
            [input]: newValue
        });
    }

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
        
        if(value && !fieldsFocusedAtLeastOnce.label){
            setFieldsFocusedAtLeastOnce({
                ...fieldsFocusedAtLeastOnce,
                [label]: value
            })
        }
    }

    const validateInputFields = (nameRef, pwdRef, cpwdRef) => {
        
        let output = { areValid: true, errors: ""}

        const nameValidationResult = validateField(FORM_FIELD_TYPE_text, [nameRef]);
        const cpwdValidationResult = validateField(FORM_FIELD_TYPE_confirm_password, [pwdRef, cpwdRef ]);

        if( nameValidationResult.isValid && cpwdValidationResult.isValid ){
            output = { 
                areValid: true, 
                errors: { 
                    "name": nameValidationResult,
                    "confirmPassword": cpwdValidationResult 
                }}
        }else{
            output = { 
                areValid: false, 
                errors: { 
                    "name": nameValidationResult,
                    "confirmPassword": cpwdValidationResult 
                }}
        }

        return output;
    }

    useEffect(()=>{
        if (didMountRef.current){

            const areInputFieldsValid = validateInputFields(
                                                            nameInputRef, 
                                                            passwordInputRef, 
                                                            confirmPasswordInputRef
                                                            );

            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: false,
                    errors: {
                        ...errorMessages.errors,
                        name: { 
                            ...errorMessages.errors.name, 
                            isValid: areInputFieldsValid.errors.name.isValid, 
                            error: areInputFieldsValid.errors.name.error},
                        confirmPassword: { 
                            ...errorMessages.errors.confirmPassword, 
                            isValid: areInputFieldsValid.errors.confirmPassword.isValid, 
                            error: areInputFieldsValid.errors.confirmPassword.error},
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });

            }else{
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        name: { 
                            ...errorMessages.errors.name, 
                            isValid: true, 
                            error: null},
                        confirmPassword: { 
                            ...errorMessages.errors.confirmPassword, 
                            isValid: true, 
                            error: null},
                        others: {
                            ...errorMessages.errors.others, 
                            isValid: true, 
                            error: null
                        }
                    }
                });
            }

        }else{
            didMountRef.current = true;
            SubmitButtonRef.current.disabled = true;
        }
    },[data]);

    const SubmitEvent = () => {

        if(isSubmitting == false)
            return;

        setLoading(true);
        dispatch(
            setPassword({
                "token": params.token,
                "name": data.name,
                "password": data.password
            })
        )
        .unwrap()
        .then(resJson => {
            setLoading(false);
            alert(TEXT_alert_message)
            navigate(ROUTE_LOGIN);
        })
        .catch(error => {
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        })
        .finally(() => setLoading(false));
    }

    return (
        <Layout
            isLoaderDisplay={loading}
            footerRelative={true}
            isDeviceMobile={isDeviceMobile}
        >
            {
                isDeviceMobile 
                ?   <>
                        <MGaaLogo onClick={handleGAAIconClick}/>
                        <MInfoAreaForm>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <MInfoAreaHeading>Clinical Trials WISE-R</MInfoAreaHeading>
                                <TrademarkLogo />
                            </div>
                            <MInfoAreaSubHeading>{TEXT_FORM_subheading}</MInfoAreaSubHeading>

                            <MInputFieldsContainer>
                                <RoundedInputField 
                                    placeholder={FORM_FIELD_PLACEHOLDER_name}
                                    type={FORM_FIELD_TYPE_text}
                                    value={data.name}
                                    innerRef={nameInputRef}
                                    required={true}
                                    className={ errorMessages.errors.name.isValid ? "" : "invalid" }
                                    onChangeCallback={(e) => {handleInputChange("name", e.target.value)}}
                                    onFocusCallback={(e) => {handleFocusChange("name", true)}}
                                    onBlurCallback={(e) => {handleFocusChange("name", false)}}
                                    keyDownCallback={SubmitEvent}
                                />
                                <Error 
                                    isVisible={ !(errorMessages.errors.name.isValid || focusedFields.name) && fieldsFocusedAtLeastOnce.name }
                                    error={ errorMessages.errors.name.error }
                                    margin={'0.5vh 3vw'}
                                />

                                <RoundedInputField 
                                    placeholder={FORM_FIELD_PLACEHOLDER_new_password}
                                    type={FORM_FIELD_TYPE_password}
                                    value={data.password}
                                    innerRef={ passwordInputRef }
                                    required={true}
                                    className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                                    onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_password, e.target.value)}}
                                    onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_password, true)}}
                                    onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_password, false)}}
                                    keyDownCallback={SubmitEvent}
                                    inputOption={ <div style={{ margin: '0 0 0 -6vw' }}><ShowOrHideOption fieldRef={passwordInputRef} /></div> }
                                />
                                <RoundedInputField 
                                    placeholder={FORM_FIELD_PLACEHOLDER_confirm_password}
                                    type={FORM_FIELD_TYPE_password}
                                    value={data.confirmPassword}
                                    innerRef={ confirmPasswordInputRef }
                                    required={true}
                                    className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                                    onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_confirm_password, e.target.value)}}
                                    onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_confirm_password, true)}}
                                    onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_confirm_password, false)}}
                                    keyDownCallback={SubmitEvent}
                                    inputOption={ <div style={{ margin: '0 0 0 -6vw' }}><ShowOrHideOption fieldRef={confirmPasswordInputRef} /></div> }
                                />
                                <Error 
                                    isVisible={ !(errorMessages.errors.confirmPassword.isValid || focusedFields.password || focusedFields.confirmPassword) && fieldsFocusedAtLeastOnce.password && fieldsFocusedAtLeastOnce.confirmPassword }
                                    error={ errorMessages.errors.confirmPassword.error }
                                    margin={'0.5vh 3vw'}
                                />
                                <Error 
                                    isVisible={ !errorMessages.errors.others.isValid }
                                    error={ errorMessages.errors.others.error }
                                    margin={'0.5vh 3vw'}
                                />

                            </MInputFieldsContainer>

                            <RoundedButton 
                                // width={'7.8vw'}
                                height={'5.2vh'}
                                fontSize={'1.8rem'}
                                textColor={"white"}
                                backgroundColor={'#23647C'}
                                borderRadius={'24px'}
                                disabled={ errorMessages.areValid ? false : true }
                                innerRef={SubmitButtonRef}
                                handleClick={SubmitEvent}
                            >
                                {TEXT_create_account}
                            </RoundedButton>
                        </MInfoAreaForm>
                    </>
                :  
                    <>
                        <InfoAreaForm>
                            <InfoAreaHeadingContainer>
                                <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" onClick={handleGAAIconClick} />
                                <div>Sign up</div>
                            </InfoAreaHeadingContainer>
                            <InfoAreaSubHeading>
                            {TEXT_FORM_subheading}
                            </InfoAreaSubHeading>

                            <InputField 
                                name={FORM_FIELD_PLACEHOLDER_name}
                                type={FORM_FIELD_TYPE_text}
                                value={data.name}
                                innerRef={nameInputRef}
                                required={true}
                                className={ errorMessages.errors.name.isValid ? "" : "invalid" }
                                onChangeCallback={(e) => {handleInputChange("name", e.target.value)}}
                                onFocusCallback={(e) => {handleFocusChange("name", true)}}
                                onBlurCallback={(e) => {handleFocusChange("name", false)}}
                                keyDownCallback={SubmitEvent}
                            />
                            <Error 
                                isVisible={ !(errorMessages.errors.name.isValid || focusedFields.name) && fieldsFocusedAtLeastOnce.name }
                                error={ errorMessages.errors.name.error }
                            />

                            <InputField 
                                name={FORM_FIELD_PLACEHOLDER_new_password}
                                type={FORM_FIELD_TYPE_password}
                                value={data.password}
                                innerRef={ passwordInputRef }
                                required={true}
                                className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                                onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_password, e.target.value)}}
                                onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_password, true)}}
                                onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_password, false)}}
                                keyDownCallback={SubmitEvent}
                                inputOption={ <div style={{ margin: '0 0 0 1vw' }}><ShowOrHideOption fieldRef={passwordInputRef} /></div> }
                            />
                            <InputField 
                                name={FORM_FIELD_PLACEHOLDER_confirm_password}
                                type={FORM_FIELD_TYPE_password}
                                value={data.confirmPassword}
                                innerRef={ confirmPasswordInputRef }
                                required={true}
                                className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                                onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_confirm_password, e.target.value)}}
                                onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_confirm_password, true)}}
                                onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_confirm_password, false)}}
                                keyDownCallback={SubmitEvent}
                                inputOption={ <div style={{ margin: '0 0 0 1vw' }}><ShowOrHideOption fieldRef={confirmPasswordInputRef} /></div> }
                            />
                            <Error 
                                isVisible={ !(errorMessages.errors.confirmPassword.isValid || focusedFields.password || focusedFields.confirmPassword) && fieldsFocusedAtLeastOnce.password && fieldsFocusedAtLeastOnce.confirmPassword }
                                error={ errorMessages.errors.confirmPassword.error }
                            />
                            <Error 
                                isVisible={ !errorMessages.errors.others.isValid }
                                error={ errorMessages.errors.others.error }
                            />

                            <OptionsContainer>
                                <div></div>
                                
                                <RoundedButton 
                                    // width={'7.8vw'}
                                    height={'4.5vh'}
                                    borderRadius={'14px'}
                                    innerRef={SubmitButtonRef}
                                    disabled={ errorMessages.areValid ? false : true }
                                    handleClick={SubmitEvent}
                                >
                                    {TEXT_create_account}
                                </RoundedButton>
                            </OptionsContainer>
                        </InfoAreaForm>
                    </>

            }
            
        </Layout>
    )
}

export default SetPassword;