import {
    DownSelectArrow,
    UserName,
    UserNameMenu,
    UserNameMenuItem,
    UserNameWrapper,
} from "./style.button";
import downSelect from "../../assets/image/down-selected.png";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import { ROUTE_RESET_PASSWORD } from "services/Constants/constant";


function UserNameBtn(props) {
    const { userLogOut } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        window.addEventListener("click", handleClickOutside, true);

        return () => {
            window.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const handleOnClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <UserNameWrapper ref={menuRef}>
            <UserName onClick={handleOnClick} isDisabled={false}>
                <span>{props.userName || "Username"}</span>
                <DownSelectArrow src={downSelect} alt="Down Select Arrow" />
            </UserName>
            <UserNameMenu isOpen={isOpen}>
                <UserNameMenuItem
                    onClick={() => navigate(ROUTE_RESET_PASSWORD)}>
                    {"Reset Password"}
                </UserNameMenuItem>
                <UserNameMenuItem
                    onClick={() => {
                        userLogOut();
                    }}
                >
                    {"Sign out"}
                </UserNameMenuItem>
            </UserNameMenu>
        </UserNameWrapper>
    );
}

export default UserNameBtn;