import { TableBody, TableHead, TableContainer, TableDataDiv } from "./style.table";

const TablePagContainer = (props) => {

    return (
        <TableContainer height={props.tableHeight}>
            <TableHead tAlign={props.theadTextAlign}>
                <tr>
                    {props.tableHeader && 
                        props.tableHeader.map((item, index) => {
                        return(
                            <th key={index}>{item}</th>
                        )
                    })}
                </tr>
            </TableHead>
            <TableBody>
                {getTableBody(props.tableData, props.currentPage, props.rowCount)}
            </TableBody>
        </TableContainer>
    );
}


const getTableBody = (data, currentPage, rowCount) => {
    const endingIndex = currentPage * rowCount;
    const startingIndex = endingIndex - rowCount;

    return (
        
                data &&
                data.length > 0 &&
                data
                    .slice(startingIndex, endingIndex)
                    .map((item, index) => {
                        return (
                            <tr key={index}>
                                {
                                    item &&
                                    item.length > 0 &&
                                    item.map((td, id) => {
                                        return (
                                            <td key={id + td} title={td}><TableDataDiv>{td}</TableDataDiv></td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
    );
}

export default TablePagContainer;