import React, { useState } from "react";
import { ChartContainer } from "./style.charts";
import { Chart as ChartJS } from 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2'
import useWindowDimensions from "hooks/useWindowDimensions";
import { numFormatter } from 'services/Function/number';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const DoughnutChart = ({ data, label, chartHeight, chartWidth, style, color}) => {



    ChartJS.register(ChartDataLabels);
    const { height, width } = useWindowDimensions();

    const color_arr = new Array(
        '#A8B97C',
        '#94B791',
        '#B8CFA7',
        '#E2EEC8',
        '#E1F2DD',
        '#E7F0D0'
    );

    const doughnutData = {
        labels: Object.keys(data),
            datasets: [
                {
                    label: `${label ? label : 'Value'}`,
                    data: Object.values(data),
                    backgroundColor: color ? color : color_arr,
                    borderColor: color ? color : color_arr,
                },
            ],
    };


    const resizeFont = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 18;
        if (height >= 1210 && width >= 1800)
            return 10;
        if (height >= 920 && width >= 1380)
            return 7;
        if (height >= 720 && width >= 1024)
            return 6;
        if (height >= 620 && width >= 768)
            return 5;
        return 4;
    };

    const resizeLegend = (height, width) => {
        if (height >= 2310 && width >= 3350)
            return 40;
        if (height >= 1210 && width >= 1800)
            return 20;
        if (height >= 920 && width >= 1380)
            return 10;
        if (height >= 720 && width >= 1024)
            return 9;
        if (height >= 620 && width >= 768)
            return 8;
        return 6;
    };

    // const handleClick = (label) => {
    //     switch (pageType) {
    //         case "Funding":
    //             dispatch(FundingFilterActions.changeFilter({key : `${filterType}`, value : label}));
    //             navigate(`/funding/`);
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const plugins = [{
        afterDraw: function(chart) {
            let width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            let fontSize = (height / 180).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            let text = "$ " + numFormatter(chart.config.options.centerText.text),
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height * 0.48;
            ctx.fillText(text, textX, textY);
            ctx.save();
        } 
    }]

    const tooltipConfig = {
        backgroundColor: 'rgba(250, 250, 249, 1)',
        titleColor: '#164050',
        bodyColor: '#164050',
        titleFont: { family: 'Libre Franklin', weight: 'bold' },
        bodyFont: { family: 'Libre Franklin' },
        caretSize: 0,
        cornerRadius: 16,
        padding: 10,
        boxWidth: 16,
        boxHeight: 16,
        boxPadding: 5,
        borderColor: 'rgba(0,0,0, 0.1)',
        borderWidth: 2,
        callbacks: {
            title: function (context) {
                let title = context[0].label;
                return title;
            },
            label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.x !== null) {
                    label += context.parsed.toLocaleString("en-US") + ' trials';
                }
                return label;
            },
            labelColor: function (context) {
                return {
                    backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                    borderWidth: 0,
                    borderRadius: 4,
                }
            },
        }
    };

    return (
        <ChartContainer width={chartWidth} height={chartHeight} style={style}>
            <Doughnut
                data={doughnutData}
                options={{
                    centerText: {
                        display: false,
                    },
                    indexAxis: 'y',
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    maintainAspectRatio: false,
                    animation: {
                        duration: 0
                    },
                    // onClick:(event, el) => {
                    //     if (el && clickable) {
                    //         const label = data["labels"][[el[0]["index"]]];
                    //         if (label !== "Not available") {
                    //             handleClick(label);
                    //         }
                    //     }
                    // },
                    // onHover: (event, el) => {
                    //     if (clickable) {
                    //         const target = event.native ? event.native.target : event.target;
                    //         target.style.cursor = el[0] ? 'pointer' : 'default';
                    //     }
                    // },
                    elements: {
                        arc: {
                            borderWidth: 0,
                            borderRadius: 4,
                            spacing: 3
                        }
                    },
                    layout: {
                        padding: 5
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: `right`,
                            align: 'end',
                            labels: { 
                                fontSize: resizeFont(height, width),
                                boxWidth: resizeLegend(height, width),
                                fontColor: "#000000",
                                pointStyle: 'rectRounded',
                                padding: 8,
                                usePointStyle: true,
                            },
                            
                        },
                        tooltip: tooltipConfig,
                        datalabels: {
                            display: false
                        }
                        
                    },
                }}
            />
        </ChartContainer>
    );
}

export default DoughnutChart;