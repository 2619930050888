import { createSlice } from "@reduxjs/toolkit";
import { sortFilters } from "../../services/Function/sortFilters";

export const defaultState = {
  filters: {
    Sponsor: [],
    Locations: [],
    PiviLocations: [],
    SurroundCountries: [],
    Phase: [],
    Active: [],
    StartYear: [],
    StudyType: [],
    CompletionYear: [],
  },
};

// const filterOrder = ["Location", "Active", "Phase", "Sponsored_by", "Year"];

const filters = createSlice({
  name: "filters/clinical",
  initialState: defaultState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters[action.payload.key] = action.payload.value;
      //   state.filters = sortFilters(filterOrder, state.filters);
    },
  },
});

export const filterActions = filters.actions;
export default filters.reducer;
