import React, { useState } from "react";
import { ChartContainer } from "./style.charts";
// import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { processNumberString, numFormatter } from "services/Function/number";
import useWindowDimensions from "hooks/useWindowDimensions";

// import { filterActions as CTFilterActions } from 'store/clinicalTrials/filtersReducer';
// import { filterActions as FundingFilterActions } from 'store/funding/filtersReducer';
// import { useDispatch } from 'react-redux';

const LineChart = ({
  data,
  pageType,
  chartHeight,
  chartWidth,
  label,
  color,
  ifFill,
}) => {
  // const dispatch = useDispatch();
  const lineData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: `${label ? label : "Value"}`,
        data: Object.values(data),
        fill: ifFill && true,
        borderColor: color ? color : "#70A9A3",
        backgroundColor: color ? color : "#70A9A3",
        strokeColor: color ? color : "#70A9A3",
        lineTension: 0.4,
        lineRadius: 6,
        skipNull: true,
      },
    ],
  };
  const { height, width } = useWindowDimensions();

  const resizeMap = (height, width) => {
    if (height >= 2310 && width >= 3350) return 21;
    if (height >= 1210 && width >= 1800) return 19;
    if (height >= 920 && width >= 1380) return 15;
    if (height >= 720 && width >= 1024) return 11;
    if (height >= 620 && width >= 768) return 9;
    return 7;
  };

  // const handleClick = (e) => {
  //     // dispatch(CTFilterActions.changeFilter({ key : `${filterType}`, value : e }));
  //     switch (pageType) {
  //         case "Clinical Trials":
  //             dispatch(CTFilterActions.changeFilter({ key : `${filterType}`, value : e }));
  //             break;
  //         case "Funding":
  //             dispatch(FundingFilterActions.changeFilter({ key : `${filterType}`, value : e }));
  //             break;
  //         default:
  //             break;
  //     }
  // }

  const resizeFont = (height, width) => {
    if (height >= 2310 && width >= 3350) return 22;
    if (height >= 1210 && width >= 1800) return 15;
    if (height >= 920 && width >= 1380) return 10;
    if (height >= 720 && width >= 1024) return 8;
    if (height >= 620 && width >= 768) return 7;
    return 6;
  };

  const resizeLegend = (height, width) => {
    if (height >= 2310 && width >= 3350) return 40;
    if (height >= 1210 && width >= 1800) return 20;
    if (height >= 920 && width >= 1380) return 10;
    if (height >= 720 && width >= 1024) return 8;
    if (height >= 620 && width >= 768) return 7;
    return 6;
  };

  const tooltipConfig = {
    backgroundColor: 'rgba(250, 250, 249, 1)',
    titleColor: '#164050',
    bodyColor: '#164050',
    titleFont: { family: 'Libre Franklin', weight: 'bold' },
    bodyFont: { family: 'Libre Franklin' },
    caretSize: 0,
    cornerRadius: 16,
    padding: 10,
    boxWidth: 16,
    boxHeight: 16,
    boxPadding: 5,
    borderColor: 'rgba(0,0,0, 0.1)',
    borderWidth: 2,
    callbacks: {
      title: function(context) {
        let title = context[0].label;
        return title;
      },
      label: function(context) {
        let label = context.dataset.label || '';

        if (label) {
            label += ': ';
        }
        if (context.parsed.y !== null) {
            label += context.parsed.y.toLocaleString("en-US") + ' trials';
        }
        return label;
      },
      labelColor: function(context) {
        return {
          backgroundColor: context.dataset.backgroundColor,
          borderWidth: 0,
          borderRadius: 4,
        }
      },
    }
  };

  return (
    <ChartContainer width={chartWidth} height={chartHeight}>
      <Line
        data={lineData}
        // backgroundColor={"rgba(200, 0, 0, 0.1)"}
        options={{
          // backgroundColor: "rgba(200, 0, 0, 0.1)",
          // layout: { padding: { top: 10, bottom: 0 } },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                switch (pageType) {
                  case "Funding":
                    const typeLabel =
                      data.datasets[tooltipItem["datasetIndex"]]["label"];
                    return `${typeLabel}: ${processNumberString(
                      tooltipItem["value"]
                    )}`;
                  case "Clinical Trials":
                    return `${processNumberString(
                      tooltipItem["value"]
                    )} trials`;
                  default:
                    return tooltipItem["value"];
                }
              },
            },
          },
          elements: {
            line: {
              borderWidth: 0,
              showLine: true,
            },
            point: {
              radius: 0,
              borderWidth: 0,
              hitRadius: 2,
            },
          },
          // onClick: (event, el) => {
          //     if (clickable) {

          //         const index = el[0]._index;
          //         const yearLables = el[0]._xScale.ticks;
          //         handleClick(yearLables[index]);
          //     }
          // },
          // onHover: (event, el) => {
          //   if (clickable) {
          //     const target = event.native ? event.native.target : event.target;
          //     target.style.cursor = el[0] ? "pointer" : "default";
          //   }
          // },
          scales: {
            // yAxes: [
            //   {
            //     ticks: {
            //       fontColor: "#164050",
            //       fontSize: resizeMap(height, width),
            //       callback: (dataLabel, index, ticks) => {
            //         return numFormatter(dataLabel);
            //       },
            //     },
            //     grid: {
            //       tickColor: "#DBEAF3",
            //     },
            //   },
            // ],
            // xAxes: [
            //   {
            //     ticks: {
            //       fontColor: "#164050",
            //       fontSize: resizeMap(height, width),
            //       beginAtZero: false,
            //       maxRotation: 0,
            //       minRotation: 0,
            //     },
            //     grid: {
            //       tickColor: "#164050",
            //     },
            //   },
            // ],
          },
          plugins: {
            legend: {
              display: false,
              position: "bottom",
              labels: {
                fontSize: resizeFont(height, width),
                boxWidth: resizeLegend(height, width),
                fontColor: "#164050",
              },
            },
            tooltip: tooltipConfig,
            datalabels: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
        }}
      />
    </ChartContainer>
  );
};

export default LineChart;
