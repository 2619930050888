import React from "react";
import { FooterContainer, FooterHeading, FooterLink, FooterLinkSeparator, LinkContainer } from "../style";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "assets/image/gaa-logo.png";
import { LINK, LINK_M } from 'services//Constants/constant';


const Footer = (props) =>{
        return (
            <FooterContainer relative={props.relative}>
                {/* Footer: Company & copyright information */}
                <FooterHeading>
                    <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
                    GLOBAL ACTION ALLIANCE&nbsp;&nbsp;|&nbsp;&nbsp;LifeScience WISE-R &#8480; a Global Action Alliance Platform
                </FooterHeading>
                <LinkContainer>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        About GAA
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Privacy Policy
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Terms and Conditions
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Located in Washington DC
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink href={LINK_M}>
                        Contact Us at contact@globalactionalliance.net
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink href={LINK}>
                        Share additional data
                    </FooterLink>
                </LinkContainer>
            </FooterContainer>
        )
}

export default Footer;