import Footer from "./Footer/footer";
import { LayoutProvider } from "./style";

import {
  GreetingsArea,
  InfoArea,
  LogInContainer,
  GreetingsHeading,
  GreetingsSubHeading,
  MBodyContainer,
  MInfoArea,
} from "../pages/Auth/styles";
import ProductTitle from "assets/image/logo.png";
import { AblLogo, TitleContainer, TrademarkLogo } from "pages/Auth/styles/style.Auth.Screen";
import Loader from "./Loader/loader";
import useWindowDimensions from "hooks/useWindowDimensions";
import { handleGAAIconClick } from "pages/Auth/utils/utils";

const Layout = (props) => {
  const TEXT_heading = "PIVI WISE-R";
  
  const { width, height } = useWindowDimensions();

  const TEXT_sub_heading =
    "An AI life science platform that aggregates, analyzes & visualizes data to provide limitless dynamic insight from any functional perspective and level of detail";

  return (
    <LayoutProvider style={{ gap: "0" }}>
      <Loader isLoaderDisplay={props.isLoaderDisplay} />
      <LogInContainer isDeviceMobile={width < height}>
        <GreetingsArea>
          <TitleContainer onClick={handleGAAIconClick}>
              <AblLogo id="org-title" src={ProductTitle} alt="ABL" />
              <TrademarkLogo />
          </TitleContainer>
          <GreetingsHeading>
              Welcome to 
              <br/>
              your dashboard
          </GreetingsHeading>
          <GreetingsSubHeading>
              {TEXT_sub_heading}
          </GreetingsSubHeading>
        </GreetingsArea>
        <InfoArea>{props.children}</InfoArea>
      </LogInContainer>
      <Footer
        relative={props.footerRelative}
        // showSingleLine={props.isDeviceMobile}
      />
    </LayoutProvider>
  );
};

export default Layout;
