import React from "react";
import { LoaderContainer, LoaderModal, LoadingImage } from "../style";
import PopupBackdrop from "./PopupsBackdrop";
import Loading from 'assets/image/loading.png';

const Loader = (props) => {
    return (
        <LoaderContainer
            isLoaderDisplay = {props.isLoaderDisplay}
        >
            {/* Loading..... */}
            <PopupBackdrop
                isDisplay={props.isLoaderDisplay}
            />
            <LoaderModal
                isDisplay={props.isLoaderDisplay}
            >
                <LoadingImage src={Loading} alt="Loading....."/>
                <p>Analyzing Query</p>
            </LoaderModal>
        </LoaderContainer>
    );
}

export default Loader;