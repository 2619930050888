export const theme = {
    heading1: {
        fontSize: '1.71rem',
        fontWeight: '400',
    },

    heading2: {
        fontSize: '1.2rem',
        fontWeight: '300',
    },

    heading3: {
        fontSize: '0.92rem',
        fontWeight: '500',
    },

    heading4: {
        fontSize: '0.75rem',
        fontWeight: '300',
    },

    heading5: {
        fontSize: '0.416667rem',
        fontWeight: '300',
    },

    heading6: {
        fontSize: '0.583rem',
        fontWeight: '300',
    },

    heading7: {
        fontSize: '0.73rem',
        fontWeight: '400',
    },

    heading8: {
        fontSize: '2.8rem',
        fontWeight: '500',
    },
    
    bodyText: {
        fontSize: '0.583333rem',
        fontWeight: '400',
    },

    colors: {
        background: '#F7F7F6',      
        generalWhite: '#FAFAF9',    // tile, filters, text, header, etc
        black: '#164050',
        darkGrey: '#6C7073',        // secondary text
        lightGrey: '#C4C4C4',       // unavailable button/ data 
        gaaGreen: '#1D7A2F',
        darkestBlue: '#23647C',     // footer, some clickable text 
        commonBlue: '#468AAB',      // filters, some text, buttons 
        secondaryBlue: '#53BBDF',   // Slider, Graphs,etc 
        lightBlue: '#DBEAF3',       // drop down highlight 
        lightestBlue: '#EEFCFE',    // text background (list view page)
    
        text: {
            generalText: "#164050",
            secondaryText: "#6C7073",
            clickableText: "#23647C",
            clickableText_2: "#468AAB",
            disabledText: "#C4C4C4",
            whiteText: "#FAFAF9",
        }
    }
}