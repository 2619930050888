import { 
    WiserLogo, 
    InfoAreaForm, 
    InfoAreaHeadingContainer, 
    InfoAreaSubHeading, 
    MInfoAreaForm,
    MInfoAreaHeading,
    MInfoAreaSubHeading,
    MInputFieldsContainer
} from "./styles";

import ProductLogo from "../../assets/image/gaa-logo.png";
import { Error, InputField, RoundedButton, RoundedInputField } from "components";
import Layout from '../../layout/authLayout'
import { useNavigate } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MGaaLogo } from "./styles/style.Auth.Mobile";
import { TrademarkLogo } from "./styles/style.Auth.Screen";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateField } from "services/Utils/Validator";
import { useAuth } from "contexts/AuthContext";
import { userLogin } from "store/Requests/auth";
import { ROUTE_HOME } from "services/Constants/constant";
import { handleGAAIconClick } from "./utils/utils";


function PreRegister(props) {

    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const isDeviceMobile = width < height;

    const { userSignIn } = useAuth();   //TODO: change for pre-register
    const emailInputRef = useRef(null);
    const registerNowButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const FORM_FIELD_TYPE_email = "email";
    const FORM_FIELD_PLACEHOLDER_email = "Your Email";
    const TEXT_register_now = 'Register Now';
    const TEXT_FORM_subheading = "We are launching Clinical WISE-R   this summer. Sign up to hear more about our latest updates and information!";


    const [ data, setData ] = useState({ email: "" });
    const [ loading, setLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ focusedFields, setFocusedFields ] = useState({
        email: false,
    });

    const [ errorMessages, setErrorMessages ] = useState({ 
        "areValid": true,
        "errors": {
            "email": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleInputChange = (label, event) => {
        setData({
            ...data,
            [label]: event.target.value
        });
    }

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
    }

    const validateInputFields = (emailRef) => {
        
        let output = { areValid: true, errors: ""}

        const emailValidationResult = validateField(FORM_FIELD_TYPE_email, [emailRef]);


        if (emailValidationResult.isValid) {
            output = { 
                areValid: true, 
                errors: { 
                    "email": emailValidationResult
                }}
        } else {
            output = { 
                areValid: false, 
                errors: { 
                    "email": emailValidationResult
                }}
        }

        return output;
    }

    useEffect(()=>{
        if (didMountRef.current) {
            const areInputFieldsValid = validateInputFields(emailInputRef);
            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: areInputFieldsValid.errors.email.isValid, 
                            error: areInputFieldsValid.errors.email.error
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            } else {
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: true, 
                            error: null
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            }
        } else {
            didMountRef.current = true;
            registerNowButtonRef.current.disabled = true;
        }
    },[data]);

    const handleSubmit = () => {

        if(isSubmitting == false)
            return;
        
        setLoading(true);
        dispatch(
            userLogin({     //TODO: update according to pre-register
                email: data.email
            })
        )
        .unwrap()
        .then(resJson => {
            userSignIn(resJson.token);  //TODO: update according to pre-register
            setLoading(false);
            navigate(ROUTE_HOME);  //TODO: update according to pre-register
        })
        .catch(error => {
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        })
        .finally(() => setLoading(false));
    }


    return (
        <Layout
            isLoaderDisplay={loading}
            footerRelative={true}
            isDeviceMobile={isDeviceMobile}
        >
            {
                isDeviceMobile 
                ?   <>
                        <MGaaLogo onClick={handleGAAIconClick}/>
                        <MInfoAreaForm>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <MInfoAreaHeading>Clinical Trials WISE-R</MInfoAreaHeading>
                                <TrademarkLogo />
                            </div>
                            <MInfoAreaSubHeading>{TEXT_FORM_subheading}</MInfoAreaSubHeading>
    
                            <MInputFieldsContainer>
                                <RoundedInputField 
                                    placeholder={FORM_FIELD_PLACEHOLDER_email}
                                    type={FORM_FIELD_TYPE_email}
                                    required={true}
                                    value={data.email}
                                    innerRef={emailInputRef}
                                    className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                                    onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_email, e)}}
                                    onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, true)}}
                                    onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, false)}}
                                    keyDownCallback={handleSubmit}
                                />

                                <Error 
                                    isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) }
                                    error={ errorMessages.errors.email.error }
                                    margin={'0.5vh 3vw'}
                                />
                                <Error 
                                    isVisible={ !errorMessages.errors.others.isValid }
                                    error={ errorMessages.errors.others.error }
                                    margin={'0.5vh 3vw'}
                                />
                            </MInputFieldsContainer>

                            <RoundedButton 
                                // width={'7.8vw'}
                                height={'5.2vh'}
                                fontSize={'1.8rem'}
                                textColor={"white"}
                                backgroundColor={'#23647C'}
                                borderRadius={'24px'}
                                disabled={ isSubmitting ? false : true }
                                innerRef={registerNowButtonRef}
                                handleClick={handleSubmit}
                            >
                                {TEXT_register_now}
                            </RoundedButton>

                        </MInfoAreaForm>
                    </>
                :  
                    <>
                        <InfoAreaForm>
                            <InfoAreaHeadingContainer>
                                <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" onClick={handleGAAIconClick} />
                                <div>Pre-Register</div>
                            </InfoAreaHeadingContainer>
                            <InfoAreaSubHeading>
                            {TEXT_FORM_subheading}
                            </InfoAreaSubHeading>

                            <InputField 
                                placeholder={FORM_FIELD_PLACEHOLDER_email}
                                type={FORM_FIELD_TYPE_email}
                                value={data.email}
                                innerRef={emailInputRef}
                                required={true}
                                className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                                onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_email, e)}}
                                onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, true)}}
                                onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, false)}}
                                keyDownCallback={handleSubmit}
                                inputOption={
                                    <div
                                        style={{ margin: '0 0 1vh 1vw' }}>
                                        <RoundedButton 
                                            width={'9vw'}
                                            height={'4.5vh'}
                                            textColor={"white"}
                                            backgroundColor={'#23647C'}
                                            borderRadius={'0.5rem'}
                                            innerRef={registerNowButtonRef}
                                            disabled={ isSubmitting ? false : true }
                                            handleClick={handleSubmit}
                                        >
                                            {TEXT_register_now}
                                        </RoundedButton>
                                    </div>    
                                }
                            />
                            <Error 
                                isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) }
                                error={ errorMessages.errors.email.error }
                            />
                            <Error 
                                isVisible={ !errorMessages.errors.others.isValid }
                                error={ errorMessages.errors.others.error }
                            />

                        </InfoAreaForm>
                    </>

            }
            
        </Layout>
    )
}

export default PreRegister