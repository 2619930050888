import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";

import GlobalStyle from "./globalStyle.js";
import { theme } from "./assets/static/index";
import "./globalStyle";
import store from "./store/index";

import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
