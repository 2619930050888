import InfoBubBtn from '../Buttons/infobubBtn';
import { GridCardWrapper, TitleWrapper, Title, CardNoData } from './style.cards';

const GridCard = (props) => {
    return (
        <GridCardWrapper
            gridRow={props.gridRow}
            gridColumn={props.gridColumn}
            padding={props.padding}
        >
            {
            props.children ?
                <>
                    {
                        props.isBlank != true &&

                        <TitleWrapper>
                            <Title>{props.title}</Title>
                            <InfoBubBtn hideInfo={props.hideInfo}/>
                        </TitleWrapper>
                    }
                    {props.children}
                </>
                :
                   <CardNoData>No Data</CardNoData>
            }
        </GridCardWrapper>
    );
}

export default GridCard;