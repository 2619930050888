import { configureStore } from "@reduxjs/toolkit";

import clinicalTrialsFilterReducer from "./ClinicalTrials/filtersReducer";
import clinicalTrialsOptionReducer from "./ClinicalTrials/optionsReducer";

import { authLoginReducer } from "./Auth/loginSlice";
import { authCreateAccountReducer } from "./Auth/createAccountSlice";
import { authForgotPasswordReducer } from "./Auth/forgotPasswordSlice";
import { authResetPasswordReducer } from "./Auth/resetPasswordSlice";
import { authSetPasswordReducer } from "./Auth/setPasswordSlice";

const store = configureStore({
  reducer: {
    clinicalFilter: clinicalTrialsFilterReducer,
    clinicalOptions: clinicalTrialsOptionReducer,

    authLogin: authLoginReducer,
    authForgotPassword: authForgotPasswordReducer,
    authSetPassword: authSetPasswordReducer,
    authResetPassword: authResetPasswordReducer,
    authCreateAccount: authCreateAccountReducer,
  },
});

export default store;
