import { SearchBar } from "./style.searchBar";
import Input from '../ComponentUtils/input';
import { SearchBtn } from "../index";

const SearchBarCont = (props) => {

    const handleSearchSubmit = () => {
        props.submitCallback(1);
    }

    return (
        <SearchBar
            width={props.width}
            height={props.height}
        >
            <Input
                value={props.searchText}
                onFocusCallback={props.handleSearchFocusCallback}
                onChangeCallback={props.handleSearchInput}
                placeholder={props.placeholder}
                keyDownCallback={handleSearchSubmit}
            />
            <SearchBtn
                handleSearchSubmit={handleSearchSubmit}
                margin={'0rem 0.2rem 0rem 0rem'}
            />
        </SearchBar>
    );
}

export default SearchBarCont;