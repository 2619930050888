import { PaginationWrapper, PageClick} from './style.pagination';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

const ComponentTablePagination = (props) => {


    return(
        <PaginationWrapper
            height = {props.height}
        >
            {
                props.numberOfPage != 1 && 
                    <PageClick 
                        disabled={props.currentPage === 1} 
                        onClick={() => { props.handlePageClick(props.currentPage - 1) }}>
                            <BsFillCaretLeftFill />
                        </PageClick>
            }
            
            <div style={{alignSelf:`${!props.center && 'flex-start'}`, width: '100%', flexGrow: '1'}}>
                {props.children}
            </div>
            
            {
                props.numberOfPage != 1 && 
                    <PageClick 
                        disabled={props.currentPage == props.numberOfPage} 
                        onClick={() => { props.handlePageClick(props.currentPage + 1) }}>
                            <BsFillCaretRightFill />
                    </PageClick>
            }
        </PaginationWrapper>
    );
}

export default ComponentTablePagination;