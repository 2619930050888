import React, { useState } from "react";
import { ChartContainer } from "./style.charts";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const HorizontalBarChart = ({
  data,
  chartHeight,
  chartWidth,
  label,
  color,
}) => {
  const color_arr = new Array(
    "#366672",
    "#437E8D",
    "#549EB0",
    "#70A9A3",
    "#82B09A",
    "#A1C4B3"
  );

  const horizontalData = {
    labels: data.map((item) => {
      return item[0];
    }),
    datasets: [
      {
        axis: "y",
        label: `${label ? label : "Value"}`,
        data: data.map((item) => {
          return item[1];
        }),
        skipNull: true,
        fill: false,
        backgroundColor: color ? color : color_arr,
        borderColor: color ? color : color_arr,
        borderWidth: 1,
      },
    ],
  };

  const tooltipConfig = {
    backgroundColor: 'rgba(250, 250, 249, 1)',
    titleColor: '#164050',
    bodyColor: '#164050',
    titleFont: { family: 'Libre Franklin', weight: 'bold' },
    bodyFont: { family: 'Libre Franklin' },
    caretSize: 0,
    cornerRadius: 16,
    padding: 10,
    boxWidth: 16,
    boxHeight: 16,
    boxPadding: 5,
    borderColor: 'rgba(0,0,0, 0.1)',
    borderWidth: 2,
    callbacks: {
      title: function(context) {
        let title = context[0].label;
        return title;
      },
      label: function(context) {
        let label = context.dataset.label || '';

        if (label) {
            label += ': ';
        }
        if (context.parsed.x !== null) {
            label += context.parsed.x.toLocaleString("en-US") + ' trials';
        }
        return label;
      },
      labelColor: function(context) {
        return {
          backgroundColor: context.dataset.backgroundColor[context.dataIndex],
          borderWidth: 0,
          borderRadius: 4,
        }
      },
    }
  };

  return (
    <ChartContainer width={chartWidth} height={chartHeight}>
      <Bar
        width={"100%"}
        height={"100%"}
        type="bar"
        data={horizontalData}
        options={{
          indexAxis: "y",
          responsive: true,
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: tooltipConfig,
            datalabels: {
              display: false,
            },
          },
        }}
      />
    </ChartContainer>
  );
};

export default HorizontalBarChart;
