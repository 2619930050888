// import { UPDATE_OPTION } from "./actionTypes";
import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  options: {
    Sponsor: [],
    Locations: [],
    PiviLocations: [],
    SurroundCountries: [],
    Phase: [],
    Active: [],
    StartYear: [],
    StudyType: [],
    CompletionYear: [],
  },
};

const options = createSlice({
  name: "option/clinical",
  initialState: defaultState,
  reducers: {
    updateOptions: (state, action) => {
      state.options[action.payload.optionKey] = action.payload.optionVal;
    },
  },
});

export const { updateOptions } = options.actions;

export default options.reducer;
