const ENDPOINT = "PRODUCTION"; // "LOCAL", "INTERNAL", "PRODUCTION"

function getAPIParams(ENDPOINT) {
  switch (ENDPOINT) {
    case "PRODUCTION":
      return ["https", "pivi-wiser.net", "443"];
    case "INTERNAL":
      return ["http", "54.173.172.94", "80"];
    case "LOCAL":
      return ["http", "localhost", "8080"];
    default:
      return ["http", "localhost", "8080"];
  }
}

const [MODE, PATH, PORT] = getAPIParams(ENDPOINT);

export { MODE, PATH, PORT };
