import { media } from "assets/static";
import styled from "styled-components";
import auth_background from "assets/image/auth_background.jpg";
import trademarkLogo from "assets/image/logo_trademark.png";

export const LogInContainer = styled.div`
    width: 100%;
    height: ${props => props.isDeviceMobile ? '97vh' : '94.5vh'}; /* To exclude footer */
    background: #F7F7F7;
    background-image: url(${auth_background});
    background-size: cover;
    background-position: center center;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
    color: ${props=> props.theme.colors.text.generalText};
`

export const GreetingsArea = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1vh;
    top: 36%;
    left: 16vw;
    width: 24%;

    ${media.monitor} {
        top: 32%;
        left: 18vw;
        width: 24%;
    }

    ${media.large_desktop} {
        top: 30%;
        left: 18vw;
        width: 23%;
    }

    ${media.laptop} {
        top: 31%;
        left: 16vw;
        width: 21%;
    }

    ${media.tablet} {
        display: none;
    }
    
    ${media.mobile} {
        display: none;
    }
`

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
`;

export const GreetingsHeading = styled.span`
    width: fit-content;
    color: ${props => props.theme.colors.text.generalText};
    
    font-size: ${props => props.theme.heading1.fontSize};
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
`

export const GreetingsSubHeading = styled.div`
    color: ${props => props.theme.colors.text.secondaryText};
    
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
`

export const InfoArea = styled.main`
    position: absolute;
    width: 15vw;
    height: fit-content;
    min-width: 370px;
    padding: 7.2vh 3.4vw;
    top: 0;
    left: 62%;
    bottom: 0;
    margin: auto 0;
    background-color: #FAFAF9E6;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    backdrop-filter: blur(25px);
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    
    ${media.monitor} {
        width: 24%;
        min-width: 360px;
        padding: 7vh 3.2vw;
        left: 62%;
    }

    ${media.large_desktop} {
        width: 22%;
        min-width: 340px;
        padding: 6.6vh 3vw;
        left: 59%;
    }

    ${media.laptop} {
        width: 24%;
        min-width: 300px;
        padding: 6.5vh 3vw;
        left: 56%;
    }

    ${media.tablet} {
        width: 50%;
        left: 0;
        right: 0;
        margin: auto;
    }

    ${media.mobile} {
        width: 50%;
        left: 0;
        right: 0;
        margin: auto;
    }
`

export const InfoAreaForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3.5vw;
`

export const InfoAreaHeadingContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.text.generalText};
    font-size: 2rem;
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
    gap: 5px;
`

export const InfoAreaSubHeading = styled.div`
    margin-top: 2.7vh;
    font-size: 0.75rem;
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: ${props => props.theme.colors.text.generalText};
`

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '2vh 0'};
`

export const LinkText = styled.a`
    color: ${props=> props.textColor ? props.textColor: '#23647C'};
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;

    &:hover {
        color: ${props=> props.theme.colors.text.clickableText}; 
    }

    &:disabled {
        color: ${props=> props.theme.colors.text.disabledText}; 
        cursor: 'not-allowed';
    }
`

export const AblLogo = styled.img`
    height: 5.8vh;
    width: 80%;

    ${media.monitor} {
        height: 5.4vh;
    }

    ${media.large_desktop} {
        height: 5vh;
    }

    ${media.laptop} {
        height: 3.8vh;
    }

`;

export const WiserLogo = styled.img`
    height: 6.8vh;
    cursor: pointer;


    ${media.monitor} {
        height: 5.4vh;
    }

    ${media.large_desktop} {
        height: 5.4vh;
    }

    ${media.laptop} {
        height: 3.8vh;
    }

`;

export const TrademarkLogo = styled.div`
    background-image: url(${trademarkLogo});
    background-repeat: no-repeat;
    background-size: 100%;
    width: ${props => props.size ? props.size : '16px'};
    height: ${props => props.size ? props.size : '16px'};
`