import React, { lazy, Suspense, Component } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Fallback } from "components";
import { AuthProvider } from "contexts/AuthContext";

import PrivateRoute from "services/Utils/PrivateRoute";
import { 
    ForgotPassword, 
    LogInForm, 
    PreRegister, 
    ResetPassword, 
    SetPassword, 
    SignupController 
} from "./pages/Auth";

// const Home = lazy(() => import ("./pages/Home/Home"));
const ClinicalTrials = lazy(() => import('./pages/ClinicalTrials/index').then(module => ({ default: module.ClinicalTrials })));

class App extends Component {

    render() {
        return (
            <Suspense fallback={<Fallback />}>
                <Router>
                    <AuthProvider>
                        <Routes>
                            <Route path="/login" element={<LogInForm />} />
                            <Route path="/pre-register" element={<PreRegister />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/set-password/:token" element={<SetPassword />} />
                            <Route path="/reset-password" element={<PrivateRoute><ResetPassword /></PrivateRoute>} />
                            <Route path="/signup" element={<SignupController />} />

                            {/* <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} /> */}
                            <Route 
                                path="/clinicalTrials" 
                                element={ <PrivateRoute> <ClinicalTrials /> </PrivateRoute> } 
                                exact 
                            />
                            <Route path="*" element={<Navigate to={"/clinicalTrials"}/>} />
                        </Routes>
                    </AuthProvider>
                </Router>
            </Suspense>
        )
    }
}

export default App;