import styled from "styled-components";

//------------HeaderBackBtn-----------------

export const HeaderBackBtnContainer = styled.div`
    width: ${(props) => props.width ? props.width : '4.4vw'};
    margin:${(props) => props.margin ? props.margin : '0 0 0 1.5vw'};

    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: ${props => props.theme.heading4.fontWeight};
    font-size: ${props => props.fontSize ? props.fontSize : props.theme.heading4.fontSize};

    display: flex;
    align-items: center;
    gap: ${(props) => props.gap ? props.gap : '0.4vw'};
    color: ${(props) => props.color ? props.color : '#F0F0F0'};;

    span{
        height: 100%;
        display: flex;
        align-items: center;
    }

    span:first-child{
        font-size: ${props => props.fontSize ? props.fontSize : '1.2rem'};;
    }

    :hover{
        cursor: pointer;
    }
`


//-------------------Search Button-------------------

export const SubmitBtn = styled.div`
    width: ${(props) => props.width ? props.width : '5.5vw'};
    min-width: 70px;
    height: ${(props) => props.height ? props.height : '100%'};

    padding: ${(props) => props.padding ? props.padding : '7px 1vh 7px 1.2vh'};
    margin: ${(props) => props.margin ? props.margin : '0px'};

    box-sizing: border-box;
    background-color: #549EB0;

    
    display: flex;
    align-items: center;
    gap: ${(props) => props.gap ? props.gap : '0.4vw'};
    color: ${(props) => props.color ? props.color : '#FFFFFF'};
    border-radius: 40px;

    font-size: ${props => props.fontSize ? props.fontSize : props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    cursor: pointer;


    span{
        height: 100%;
        display: flex;
        align-items: center;
    }

    span:first-child{
        font-size: ${props => props.fontSize ? props.fontSize : '1rem'};;
    }
`

//----------------UserNameBtn----------------

export const DownSelectArrow = styled.img`
    height: 0.6vw;
    padding: 1vh;
`;
export const UserNameWrapper = styled.div`
    display: block;
    position: relative;
`;

export const UserName = styled.div`
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.theme.heading4.fontSize};

    width: 7vw;
    margin-right: 1.5vw;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.2vw;
    color: #FFFFFF;

    cursor: pointer;
`;

export const UserNameMenu = styled.div`
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    gap: 5px;
    position: absolute;
    right: 1.5vw;
    outline: none;
    z-index: 10;

    max-height: 17vh;
    overflow-y: auto;

    color: ${(props) => props.theme.colors.text.generalText};
    padding: 2vh 1vw;
    width: max-content;
    max-width: 14vw;
    height: auto;
    background: white;

    font-size: ${(props) => props.theme.bodyText.fontSize};
    font-weight: ${(props) => props.theme.bodyText.fontWeight};
    border-radius: 10px;
    cursor: pointer;
`;

export const UserNameMenuItem = styled.div``;

//-----------Info Btn------------------------
export const InfoBub = styled.div`
    font-size:${props => props.theme.heading3.fontSize};
    display: ${props => props.hideInfo == true && 'none'};
    color: #437E8D;
`

export const TopScrollBtnContainer = styled.div`
    width: ${(props) => props.width ? props.width : '6vw'};
    height: ${(props) => props.height ? props.height : '4.11vh'};
    ${'' /* margin: ${(props) => props.margin ? props.margin : '0 3.67vw 0 0'}; */}

    position: -webkit-sticky;
    position: sticky;
    
    top: 86vh;
    left: 89vw;

    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : '#549EB0'};
    border-radius: 16px;

    color: ${(props) => props.color ? props.color : 'white'};
    font-size: ${props => props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    line-height: 1.2em;
    /* text-align: center; */

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9vh 0.93vw;
    gap: ${(props) => props.gap ? props.gap : '10px'};

    cursor: pointer;
    z-index: 30;
`;

//----------------- Rounded Button -----------------
export const RoundedButtonContainer = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${(props) => (props.width ? props.width : "")};
    height: ${(props) => (props.height ? props.height : "")};
    box-sizing: border-box;

    font-size: ${(props) =>
            props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
    font-weight: ${(props) => props.theme.heading4.fontWeight};

    color: ${(props) =>
            props.textColor ? props.textColor : props.theme.colors.text.whiteText};
    background: ${(props) =>
            props.backgroundColor
                ? props.backgroundColor
                : "#549EB0"};

    padding: 0.5vh 1vw;
    border: ${(props) => (props.border ? props.border : "")};
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
    border-radius: ${(props) =>
            props.borderRadius ? props.borderRadius : "27.5px"};
    border-width: 0px;
    cursor: pointer;

    &:hover {
        background: #437E8D;
        color: ${(props) => props.theme.colors.text.whiteText};
    }

    &:disabled {
        color: ${(props) => props.theme.colors.text.generalText};
        background-color: #dededd;
        cursor: auto;
    }
    `;

export const TextButtonContainer = styled.div`
    text-align: center;
    line-height: normal;
    font-size: ${(props) =>
        props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
    font-weight: ${(props) => props.theme.heading4.fontWeight};
    color: ${(props) =>
        props.textColor ? props.textColor : props.theme.colors.text.clickableText};

    cursor: pointer;

    &:hover {
        color: #549EB0;
    }
`;
