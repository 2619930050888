import { 
    WiserLogo, 
    InfoAreaHeadingContainer, 
    InfoAreaSubHeading, 
    MInfoAreaForm,
    MInputFieldsContainer
} from "./styles";

import ProductLogo from "../../assets/image/gaa-logo.png";
import { 
    Error,  
    RoundedButton, 
    RoundedInputField, 
    TextButton
} from "components";
import Layout from '../../layout/authLayout'
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateField } from "services/Utils/Validator";
import { forgotPassword } from "store/Requests/auth";
import { ROUTE_HOME, ROUTE_LOGIN } from "services/Constants/constant";
import { handleGAAIconClick } from "./utils/utils";


function ForgotPassword(props) {

    const navigate = useNavigate();

    const emailInputRef = useRef(null);
    const SubmitButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const FORM_FIELD_TYPE_email = "email";
    const FORM_FIELD_PLACEHOLDER_email = "Company email ID";
    const TEXT_submit = 'Submit';
    const TEXT_back = "< back";
    const TEXT_alert_message = "Password reset link has been sent to your Email ID. Kindly check your email.";
    const TEXT_FORM_subheading = "Please enter your company credentials associated to your account. You will receive an email message with instructions on how to reset your password.";

    const [ data, setData ] = useState({ email: "" });
    const [ loading, setLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ focusedFields, setFocusedFields ] = useState({
        email: false,
    });

    const [ errorMessages, setErrorMessages ] = useState({ 
        "areValid": true,
        "errors": {
            "email": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleInputChange = (label, event) => {
        setData({
            ...data,
            [label]: event.target.value
        });
    }

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
    }

    const validateInputFields = (emailRef) => {
        
        let output = { areValid: true, errors: ""}

        const emailValidationResult = validateField(FORM_FIELD_TYPE_email, [emailRef]);

        output = { 
            areValid: emailValidationResult.isValid, 
            errors: { 
                "email": emailValidationResult
        }}

        return output;
    }

    useEffect(()=>{
        if (didMountRef.current) {
            const areInputFieldsValid = validateInputFields(emailInputRef);
            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: areInputFieldsValid.errors.email.isValid, 
                            error: areInputFieldsValid.errors.email.error
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            } else {
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: true, 
                            error: null
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            }
        } else {
            didMountRef.current = true;
            SubmitButtonRef.current.disabled = true;
        }
    },[data]);

    const handleSubmit = () => {

        if(isSubmitting == false)
            return;
        
        setLoading(true);
        dispatch(
            forgotPassword({     
                email: data.email
            })
        )
        .unwrap()
        .then(resJson => {
            setLoading(false);
            if(resJson.success){
                alert(TEXT_alert_message)
                navigate(ROUTE_LOGIN);
            }
        })
        .catch(error => {
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        })
        .finally(() => setLoading(false));
    }


    return (
        <Layout
            isLoaderDisplay={loading}
            footerRelative={true}
        >
            <MInfoAreaForm>
                <InfoAreaHeadingContainer>
                    <WiserLogo
                        id="org-logo"
                        src={ProductLogo}
                        alt="GAA"
                        onClick={handleGAAIconClick}
                    />
                    <div>Forgot Password</div>
                </InfoAreaHeadingContainer>
                <InfoAreaSubHeading>{TEXT_FORM_subheading}</InfoAreaSubHeading>
                
    
                <MInputFieldsContainer>
                    <RoundedInputField 
                        placeholder={FORM_FIELD_PLACEHOLDER_email}
                        type={FORM_FIELD_TYPE_email}
                        required={true}
                        value={data.email}
                        innerRef={emailInputRef}
                        className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleInputChange(FORM_FIELD_TYPE_email, e)}}
                        onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, true)}}
                        onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_email, false)}}
                        keyDownCallback={handleSubmit}
                    />

                    <Error 
                        isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) }
                        error={ errorMessages.errors.email.error }
                        margin={'0.5vh 1vw'}
                    />
                    <Error 
                        isVisible={ !errorMessages.errors.others.isValid }
                        error={ errorMessages.errors.others.error }
                        margin={'0.5vh 1vw'}
                    />
                </MInputFieldsContainer>

                <RoundedButton 
                    height={'5.2vh'}
                    borderRadius={'14px'}
                    disabled={ isSubmitting ? false : true }
                    innerRef={SubmitButtonRef}
                    handleClick={handleSubmit}
                >
                    {TEXT_submit}
                </RoundedButton>
                <TextButton
                    style={{ marginTop: '2.5vh' }}
                    text={TEXT_back} 
                    textColor={'#437E8D'}
                    handleClick={() => navigate(ROUTE_HOME)}
                />
            </MInfoAreaForm>
                    
        </Layout>
    )
}

export default ForgotPassword