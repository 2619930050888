import styled, { keyframes } from "styled-components";


export const SearchBar = styled.div`
    width: ${props => props.width ? props.width : '32vw'};
    height: ${props => props.height ? props.height : '3vh'};
    min-width: 400px;
    min-height: 25px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    padding: 5px 2px 5px 2vw;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.1);
    border-radius: 76.5201px;

    background-color: #FAFAF9;


    & > input {
        width: 100%;
        background: transparent;
        color: #549EB0;
        border: none;
        font-size: ${props=> props.theme.heading4.fontSize};
        font-weight: ${props => props.theme.heading4.fontWeight};
        
        ::placeholder{
            color: #549EB0;;
        }
    }

    & > input:focus {
        outline: none;
    }
`
